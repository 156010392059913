import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "100px 0 100px 0",
	"background": "#ffffff",
	"quarkly-title": "FAQ-5"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 600 42px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "FAQ (часто задаються питання)"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 70px 0px",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"sm-margin": "0px 0px 50px 0px",
			"children": "Перейдіть через найпоширеніші питання, щоб отримати уявлення про наші пропозиції продуктів, процес замовлення та доставку."
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "50px 50px",
			"md-grid-template-columns": "1fr",
			"sm-grid-gap": "35px 0"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Які продукти ви пропонуєте?"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Ми пропонуємо кураторний вибір футбольного обладнання, тренерів та аксесуарів, пристосованих спеціально для тренажерних залів, судів та навчальних арен."
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Як мені замовити замовлення?"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Просто зверніться до нас через нашу контактну форму або електронну пошту, і наша команда проведе вас через процес замовлення."
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Ви пропонуєте масові або на замовлення замовлення?"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Абсолютно!Будь ласка, зв'яжіться з нашою командою з вашими вимогами, і ми будемо більш ніж раді допомогти."
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Де я можу знайти прайс -лист та лінійку продуктів?"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Щоб отримати найсучасніші ціноутворення та інформацію про товар, надішліть нам електронний лист.Ми негайно надамо вам усі необхідні деталі."
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Яка ваша політика доставки?"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Ми пишаємося швидкими поставками.Конкретні деталі та витрати на доставку можуть бути обговорені під час замовлення."
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Чи можу я повернути чи обмінюватися продуктами?"
		}
	},
	"text13": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Так, у нас є гнучка політика повернення та обміну."
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text14": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Як я можу відстежувати своє замовлення?"
		}
	},
	"text15": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Після того, як ваше замовлення буде відправлено, ми надамо вам інформацію про відстеження електронною поштою."
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text16": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"children": "Ви пропонуєте міжнародну доставку?"
		}
	},
	"text17": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#555a5f",
			"children": "Так, ми обслуговуємо як вітчизняних, так і міжнародних клієнтів.Коефіцієнти доставки та часи можуть змінюватися залежно від місця призначення."
		}
	}
};

const Faq = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text2")} />
				<Text {...override("text3")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text4")} />
				<Text {...override("text5")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text6")} />
				<Text {...override("text7")} />
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text8")} />
				<Text {...override("text9")} />
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text10")} />
				<Text {...override("text11")} />
			</Box>
			<Box {...override("box6")}>
				<Text {...override("text12")} />
				<Text {...override("text13")} />
			</Box>
			<Box {...override("box7")}>
				<Text {...override("text14")} />
				<Text {...override("text15")} />
			</Box>
			<Box {...override("box8")}>
				<Text {...override("text16")} />
				<Text {...override("text17")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Faq, { ...Section,
	defaultProps,
	overrides
});
export default Faq;