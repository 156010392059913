import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "90px 0 50px 0",
	"background": "--color-dark",
	"quarkly-title": "Footer-13",
	"sm-padding": "50px 0 50px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "50px 30px 50px 30px",
			"background": "linear-gradient(180deg,rgba(0, 0, 0, 0.3) 0.5%,rgba(0, 0, 0, 0.3) 99%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://ua.woodsua.com/img/4.jpg) 0% 100% /cover repeat scroll padding-box",
			"display": "flex",
			"justify-content": "space-around",
			"align-items": "center",
			"margin": "0px 0px 80px 0px",
			"md-flex-direction": "column",
			"md-align-items": "stretch",
			"md-margin": "0px 0px 50px 0px",
			"md-padding": "30px 30px 30px 30px",
			"sm-padding": "20px 15px 20px 15px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "90%",
			"md-margin": "0px 0px 30px 0px",
			"md-width": "100%",
			"sm-margin": "0px 0px 18px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 400 42px/1.2 --fontFamily-sansHelvetica",
			"color": "--light",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 12px 0px",
			"children": "Доставка та додаткова інформація"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "normal 300 20px/1.5 --fontFamily-sans",
			"children": "Для точних розрахунків доставки, строків доставки, запитів на замовлення або будь-якої іншої допомоги, будь ласка, зверніться до нас електронною поштою. Ми тут, щоб переконатися, що ваш досвід покупок є безшовним."
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 0px 80px 0px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "36px 0",
			"md-margin": "0px 0px 50px 0px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#6d32ec",
			"md-margin": "0px 0px 20px 0px",
			"children": "Адреса"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": "пр. Коцюбинського 30, м. Вінниця, 21000"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#6d32ec",
			"md-margin": "0px 0px 20px 0px",
			"children": "Контактний телефон"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:0432 277 695",
			"color": "#d7c7c5",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"display": "flex",
			"text-align": "center",
			"margin": "0px 0px 15px 0px",
			"hover-color": "#f5eceb",
			"children": "0432 277 695"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#6d32ec",
			"md-margin": "0px 0px 20px 0px",
			"children": "Email"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"children": "info@ua.woodsua.com"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"color": "#d7c7c5",
			"md-margin": "0px 0px 25px 0px",
			"sm-text-align": "center",
			"text-align": "center",
			"children": "© 2024 ua.woodsua.com, Всі права захищені."
		}
	}
};

const Footer13 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Box {...override("box3")}>
				<Text {...override("text2")} />
				<Text {...override("text3")} />
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text4")} />
				<Link {...override("link")} />
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text5")} />
				<Text {...override("text6")} />
			</Box>
		</Box>
		<Text {...override("text7")} />
		{children}
	</Section>;
};

Object.assign(Footer13, { ...Section,
	defaultProps,
	overrides
});
export default Footer13;